import React, { useEffect } from "react";
import { TemplatesCoreProvider, TemplatesLoader } from "@newageerp/v3.templates.templates-core";
import { templatesComponentsMap } from "@newageerp/v3.templates.templates-components-map"
import { MainBundle } from "@newageerp/v3.app.main-bundle";
import '@newageerp/v3.app.main-bundle/dist/main-bundle.css'

import { PluginsMap } from "../../../Plugins/PluginsMap";
import ResolverViewFormCustomField from "../../../Plugins/Resolvers/ViewForm/CustomField/ResolverViewFormCustomField";

function App() {
    useEffect(() => {
        window.document.title = `Loading...`;
    }, []);

    return (
        <MainBundle>
            <TemplatesCoreProvider
                resolvers={{
                    'window.view.form': {
                        customField: [
                            (opt: any) => <ResolverViewFormCustomField {...opt} />
                        ]
                    },
                }}
                templatesMap={templatesComponentsMap}
                pluginsMap={PluginsMap}
                dataCache={{
                    cacheData: () => [],
                    fields: {},
                }}
                modules={{
                    properties: {

                    },
                }}
            >
                <TemplatesLoader templateName="App" />
            </TemplatesCoreProvider>
        </MainBundle>
    );
}

export default App;
