import { RJSFSchema } from '@rjsf/utils';

export const CrmConfigSchema: RJSFSchema = {
    type: "object",
    properties: {
        url: { type: 'string' },
        sessionUrl: {type: 'string'},
        cartUrl: {type: 'string'},
        source: {type: 'string'},
    }
  }